.btn {
	background: none;
	font-size: 20px;
	color: rgb(255, 255, 255);
	height: 100%;
	width: 100%;
	padding-block: 10px;
	/* background-color: rgb(4, 101, 161); */
	color:rgb(0, 122, 204);
	border: none;
	cursor: pointer;
	border-radius: 2px;
}	

@media (max-width: 365px){
	.btn{
		padding-block: 25px;
	}
}