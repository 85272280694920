.container_ {
	background-image: url('../assets/Backgrounds/bg13.jpg');

	/* Set a specific height */
	min-height: 400px;
	/* Create the parallax scrolling effect */
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
}

body {
	overflow-x: hidden;
}
.container_ p {
	text-transform: uppercase;
	letter-spacing: 0.5em;
	display: inline-block;
	border: 4px double rgba(255, 255, 255, 0.25);
	border-width: 4px 0;
	padding: 1.5em 0em;
	/* /position: absolute;
	top: 18%;
	left: 50%; */
	align-self: center;
	width: 60%;
	text-align: center;
}
.container_ p span {
	font: 700 4em/1 'Oswald', sans-serif;
	letter-spacing: 0;
	padding: 0.25em 0 0.325em;
	display: block;
	margin: 0 auto;
	text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
	/* Clip Background Image */
	text-align: center;
	background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	-webkit-background-clip: text;
	background-clip: text;
	/* Animate Background Image */
	-webkit-text-fill-color: transparent;
	-webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
}
.container_ .box__ {
	display: flex;
	flex-direction: column;
}
/* Animate Background Image */
@keyframes aitf {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

@media (max-width: 800px) {
	.container_ p {
		font-size: 20px;
		width: 80%;
		padding: 0;
		margin: 0;
	}
}
