* {
	margin: 0;
	padding: 0;
}

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10000000000;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #242830;
}

.navlinks-container {
	align-self: center;
	width: 50%;
	display: flex;
	justify-content: space-between;
}
.logo {
	align-self: center;
	padding: 5px;
	margin-inline-start: 2rem;
}

.logo img {
	transform: scale(1.2);
}

.ham {
	align-self: center;
	color: white;
	/* margin-inline: 5px; */
	display: none;
}

.mobile-navlink-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* z-index: 10; */
	width: 100vw;
	height: 100vh;
	padding: 1em;
	/* background-color: rgb(11, 11, 105); */
	animation: appear 1s;
}
.CrossHam {
	margin-top: 0px;
	margin-bottom: 16px;
}
@keyframes appear {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}

.hide {
	display: none;
	animation: disappear 1s;
}

@keyframes disappear {
	from {
		opacity: 100%;
	}
	to {
		opacity: 0%;
	}
}

.align-left {
	align-self: end;
}
.hamImg {
	width: 40px;
	height: 35px;
}

@media (max-width: 1000px) {
	.navlinks-container {
		display: none;
	}
	.ham {
		display: block;
	}
	.logo {
		margin-inline-start: 1rem;
	}
}
