.hero {
	padding-top: 224px;
	overflow: hidden;
}

#player span {
	display: block;
}
#player .player-info {
	font-size: 14px;
}

input.progress {
	cursor: ew-resize;
}

input.progress[value] {
	position: relative;
	width: 100%;
	height: 27px;
	-webkit-appearance: none;
	background-color: transparent;
	color: #0270d7;
}

input.progress[value]:focus {
	outline: none;
}

input.progress[value]:after {
	content: '';
	position: absolute;
	top: 13px;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: currentColor;
}

input.progress[value]::-webkit-slider-thumb {
	-webkit-appearance: none;
	background-color: currentColor;
	border-radius: 50%;
	width: 16px;
	height: 16px;
}
