.eventImage {
	/* width: auto; */
	/* height: 100%; */
	/* border-radius: 10%; */
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
	padding: 0;
	margin: 0;
}

.eventImage img {
	width: 100%;
	height: 250px;
	/* border: 1px solid red; */
	z-index: 20;
	border-radius: 10%; 
	padding: 0;
	margin: 0;

}

@media (max-width: 1200px) {
	.eventImage {
		min-width: 100%;
		min-height: 200px;
	}
	.eventImage img{
		height: 200px;
	}
}
