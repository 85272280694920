.modalBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	z-index: 30;

	/* background: url('../../assets/Backgrounds/bg2.jpg');
        background-position: center;
            background-repeat: no-repeat;
            background-size: cover; */
	background-color: rgba(0, 0, 0, 0.85);
	animation: appear 1s;
	animation-direction: alternate;
}

.modalCard {
	position: fixed;
	top: 25%;
	left: 27%;
	width: 45vw;
	height: auto;
	z-index: 40;
	background-color: rgb(234, 230, 230);
	/* background: url('../../assets/Backgrounds/bg2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */

	color: rgb(9, 9, 9);

	/* border: 1px solid red; */

	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: space-evenly; */
	/* padding: 10px; */
	border-radius: 15px;

	animation: appear 0.5s;
	animation-direction: alternate;
	overflow-y: scroll;
}

.website {
	font-size: 20px;
	padding-inline: 15px;
	padding-block: 10px;
	/* border: 1px solid rgba(0, 140, 2, 0.527); */
	border-radius: 3px;
	text-decoration: none;
	text-align: center;
	color: white;
	background-color: #e8be01;
	width: 100%;
}

.website:hover {
}

.buttonContainer {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	padding: 0;
	margin: 0;
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.close {
	align-self: flex-start;
	width: 100%;
	text-align: right;
	cursor: pointer;
	background-color: rgb(18, 18, 17);
}

.eventHeading {
	/* font-size: 10px; */
	font-size: 28px;
	margin-top: px;
	margin-bottom: 3px;
	line-height: 0;
	color: #f06f2b;
}

.title {
	/* font-size: 10px; */
	text-align: center;
	font-size: 20px;
	margin: 0px;
	padding: 0;
	color: rgb(0, 0, 0);
}

.description {
	text-align: justify;
	font-size: 18px;
	padding-inline: 30px;
	line-height: 20px;
	color: rgb(0, 0, 0);
	opacity: 0.7;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: none;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(
		rgba(255, 255, 255, 0) 0%,
		#f06f2b 35%,
		rgba(255, 255, 255, 0) 100%
	);
	opacity: 0.8;
}

@media (max-width: 1000px) {
	.modalCard {
		width: 94%;
		top: 40%;
		left: 3%;
		margin-inline: auto;
		top: 13%;
		height: 80vh;
		overflow-y: scroll;
	}

	.buttonContainer {
		width: 90%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
