* {
	padding: 0;
	margin: 0;
}

.footer-container-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	position: relative;
	width: 100%;
	color: white;
	background-color: #23272f;
	min-height: 50vh;
}

.footer-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;
	position: relative;
	width: 70%;
	color: white;
	/* background-color: rgba(11, 32, 70, 0.541); */
}

.footer-left {
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}
/* a{
    color:white;
    text-decoration: none;
} */
.text {
	color: white;
	text-decoration: none;
}
.footer-mid {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer-right {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer-button {
	height: 40px;
	padding: 0 30px;
	border-radius: 50px;
	cursor: pointer;
	box-shadow: 0px 15px 20px rgba(54, 24, 79, 0.5);
	z-index: 3;
	color: #695681;
	background-color: white;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1rem;
	transition: all 0.3s ease;
}

/* .footer-button:hover{
	box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
	transform: translateY(5px);
	
	background-color: #fe1746;
	color: white;
} */

.footer-block {
	margin-top: 0.5rem;
	/* align-self: flex-start; */
}

.footer-block p {
	text-align: center;
	margin-bottom: 0.5rem;
	transition: all ease 0.5s;
}

.footer-block p:hover {
	color: #f06f2b;
	transform: scale(1.01);
}

.conduct-block {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	/* align-self: flex-start; */
}

.conduct-block a {
	transition: all ease 0.5s;
}

@media (max-width: 800px) {
	.conduct-block {
		flex-direction: column;
		justify-content: center;
		align-items: space-between;
	}
}

.center-text {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	/* padding-bottom: 1rem; */
	margin-inline: 3rem;
	margin-top: none;
}

/* .center-text:hover{
	font-size: 1.2em;
	color:#fca311;
} */
.conduct-block a:hover {
	transform: scale(1.01);
	color: #f06f2b;
}

.center-text-button {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	/* padding-bottom: 8px; */
}

.social-link-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* align-items: center; */
}

.social-link {
	margin: auto;
	margin-bottom: 0.5rem;
}

.social-link-container .icon {
	position: relative;
	width: 60%;
	height: 50px;
	padding: 0;
	border-radius: 50px;
	transition: all 0.8s;
}

.fab {
	font-weight: 400;
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.social-icon {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
}

.social-icon .icon {
	position: relative;
	width: 50px;
	height: 50px;
	padding: 0;
	border-radius: 50px;
	transition: all 0.8s;
}

.icon:hover {
	background: #e60073;
	/* box-shadow: 0 0 0px #e60073; */
}

.icon:hover:nth-child(2) {
	background: #1877f2;
	/* box-shadow: 0 0 20px #1877f2; */
}

.icon:hover:nth-child(3) {
	background: #0e76a8;
	/* box-shadow: 0 0 20px #0e76a8; */
}

.icon i {
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.instagram:hover {
	color: #e60073;
	box-shadow: 0 0 0px #e60073;
}

.facebook:hover {
	color: #1877f2;
	box-shadow: 0 0 0px #1877f2;
}

.linkedin:hover {
	color: #0e76a8;
	/* box-shadow: 0 0 0px #0e76a8; */
}

@media (max-width: 800px) {
	.footer-container {
		flex-direction: column;
		padding: 1rem 0;
		align-items: center;
	}

	.social-icon {
		margin-bottom: 1rem;
	}

	.footer-left,
	.footer-mid,
	.footer-right {
		width: 70%;
	}

	.footer-button {
		font-size: 0.7rem;
		padding: 0 10px;
	}

	.footer-block .center-text {
		margin-inline: 0;
	}
}

.footer-logo {
	width: 300px;
	height: 150px;
}

h3 {
	text-align: center;
	font-size: 20px;
}

.line {
	width: 70%;
	margin-block: none;
	margin-inline: 5%;
	background-color: #f06f2b;
	height: 1px;
}

.copyright {
	margin: 1rem;
	text-align: center;
}

.tandc {
	margin: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 70%;
	text-align: center;
	color: #0270d7;
}

@media (max-width: 822px) {
	.line {
		width: 90%;
	}

	.tandc {
		width: 100%;
	}
}
