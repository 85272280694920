.eventStyle{
    /* min-height: 350px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    z-index:20;
    margin-block-end: 1.5rem;
    margin-inline: 2rem;
    background: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    animation: appear 2s;
    padding-inline: 5px;
}

.eventStyle:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.website {
    font-size: 20px;
    padding-block: 10px;
    border-radius: 3px;
    text-decoration: none;
    color: rgb(0, 122, 204);
    /* background-color: #e8be01; */
    width: 100%;
    text-align: center;
}

@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}