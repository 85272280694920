.card-profile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 250px;
	width: 240px;
}

/* .hover:hover {
	translate: scale(1.1);
} */
